import React from 'react'

const Page = () => {
    return (
        <div>
            Page
        </div>
    )
}

export default Page